import circleAPI from '@/api/circles';
import accountsAPI from '@/api/account';
import checkFor404 from '../helpers/checkFor404';

export default {
  namespaced: true,

  state: () => ({
    circle: null,
    accounts: [],
  }),

  actions: {
    setActiveCircle({ commit }, circle) {
      commit('SET_CIRCLE', circle);
    },

    setCircleAccounts({ commit }, accounts) {
      commit('SET_CIRCLE_ACCOUNTS', accounts);
    },

    async refreshCircle({ commit }, { circle_uuid }) {
      await circleAPI
        .get(circle_uuid)
        .then((rsp) => {
          commit('SET_CIRCLE', rsp.data.data);

          accountsAPI
            .getAccounts({
              params: {
                per_page: 100,
                include: 'custom_fields,groups,tags,assigned_adviser,assigned_administrator',
                'filter[uuid]': rsp.data.data.accounts.map((i) => i.uuid).join(','),
              },
            })
            .then((accountRsp) => {
              commit('SET_CIRCLE_ACCOUNTS', accountRsp.data.data);
            })
            .catch((err) => {
              checkFor404.process(err);
            });
        })
        .catch((err) => {
          checkFor404.process(err);
        });
    },
  },

  mutations: {
    SET_CIRCLE(state, circle) {
      state.circle = circle;
    },

    SET_CIRCLE_ACCOUNTS(state, accounts) {
      state.accounts = accounts;
    },

    CLEAR_CIRCLE(state) {
      state.circle = null;
      state.accounts = [];
    },
  },
};
