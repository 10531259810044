export default {
  async openModal(modalName, r, to) {
    await $nuxt.$store.dispatch('modal/addRequest', modalName);
    r.push(to);
  },
  listen(modalName, callback) {
    $nuxt.$on('openModal-' + modalName, async () => {
      await $nuxt.$store.commit('modal/DELETE_REQUEST', modalName);
      callback();
    });
  },
};
