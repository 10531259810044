export default {
  namespaced: true,

  state: () => ({
    clickInteraction: true,
    navigationItems: [
      {
        to: '/dashboard',
        icon: 'fa-home',
        text: 'Dashboard',
        children: [
          {
            to: '/dashboard',
            text: 'My overview',
            restricted: [],
          },
          {
            to: '/dashboard/my-logged-time',
            text: 'My logged time',
            restricted: [],
          },
          {
            to: '/dashboard/my-tasks',
            text: 'My tasks',
            restricted: [],
          },
        ],
        restricted: [],
      },
      {
        to: '/contacts',
        icon: 'fa-users',
        text: 'Contacts',
        children: [
          {
            to: '/contacts',
            text: 'Contacts',
            restricted: [],
          },
          {
            to: '/circle',
            text: 'Circles',
            restricted: [],
          },
          {
            to: '/plans',
            text: 'Plans',
            restricted: [],
          },
        ],
        restricted: [],
      },
      {
        to: '/cases',
        icon: 'fa-folders',
        text: 'Cases',
        restricted: [],
      },
      {
        to: '/business/key-metrics',
        icon: 'fa-building',
        text: 'Business',
        children: [
          {
            to: '/business/key-metrics',
            text: 'Business',
            restricted: [],
          },
          {
            to: '/business/key-metrics',
            text: 'Key Metrics',
            restricted: [],
          },
          {
            to: '/automations',
            text: 'Automations',
            restricted: [],
          },
          {
            to: '/business/docs',
            text: 'Document Builder',
            restricted: [],
          },
          {
            to: '/business/forms',
            text: 'Form Builder',
            restricted: [],
          },
          {
            to: '/business/signatures',
            text: 'Signature Requests',
            restricted: [],
          },
          {
            to: '/business/income-reconciliation',
            text: 'Income Reconciliation',
            restricted: ['owners', 'admins'],
          },
          {
            to: '/business/reporting',
            text: 'Reporting / Exports',
            restricted: ['owners', 'admins'],
          },
          {
            to: '/business/imports',
            text: 'Imports',
            restricted: [],
          },
        ],
        restricted: [],
      },
      {
        to: '/settings',
        icon: 'fa-gear',
        text: 'Settings',
        restricted: ['owners', 'admins'],
      },
    ],
    clientItems: [
      {
        to: '/portal',
        icon: 'home',
        text: 'Dashboard',
      },
      {
        to: '/portal/my-details',
        icon: 'person',
        text: 'About Me',
      },
      {
        to: '/portal/conversations',
        icon: 'chat',
        text: 'Conversations',
      },
      {
        to: '/portal/documents',
        icon: 'folder',
        text: 'Documents',
      },
      {
        to: '/portal/my-tasks',
        icon: 'square-tick',
        text: 'My Tasks',
      },
      {
        to: '/portal/my-wealth',
        icon: 'bar-chart',
        text: 'My Wealth',
      },
    ],
  }),
};
