import navigatorAPI from '~/api/navigator';
import tasksApi from '~/api/tasks';
import intentManager from '~/helpers/intentManager';
import notificationAPI from '@/api/notifications';
import signatureAPI from '@/api/signatures';

export default {
  namespaced: true,

  state: () => ({
    notifications: [],
    unread: 0,
    meta: {},
    openConversationUuid: null,
    notificationPopUpClose: false,
    include_read: false,
  }),

  actions: {
    async refreshNotifications({ commit, state }) {
      let filters = {};

      if (state.include_read === false) {
        filters['filter[read]'] = false;
      }

      await notificationAPI.getNotifications({ params: { ...filters } }).then((rsp) => {
        commit('SET_NOTIFICATIONS', rsp.data.data);
        commit('SET_META', rsp.data.meta);
        commit('SET_UNREAD_COUNT', rsp.data.unread);
      });
    },

    changePage({ commit }, url) {
      this.$axios.get(url).then((rsp) => {
        commit('SET_NOTIFICATIONS', rsp.data.data);
        commit('SET_META', rsp.data.meta);
      });
    },

    toggleIncludeRead({ commit, dispatch }, data) {
      commit('SET_INCLUDE_READ', data);
      dispatch('refreshNotifications');
    },

    redirectTo({ commit, dispatch, rootGetters }, notification) {
      commit('SET_NOTIFICATION_POP_UP_CLOSE');

      if (!rootGetters['user/isClient']) {
        if (notification.data.model_type === 'client' || notification.data.model_type === 'account') {
          this.$router.push('/contacts/' + notification.data.model_uuid);
        }
        if (notification.data.model_type === 'mailbox') {
          this.$router.push('/settings/email');
        }
        if (notification.data.model_type === 'automation_execution') {
          this.$router.push('/automations/' + notification.data.model_uuid);
        }
        if (notification.data.model_type === 'form_submission') {
          this.$router.push(
            '/contacts/' +
            notification.data.metadata.author_uuid +
            '/forms/' +
            notification.data.metadata.form_uuid +
            '/submissions/' +
            notification.data.metadata.submission_uuid
          );
        }

        if (notification.data.model_type === 'form') {
          this.$router.push('/dashboard/my-forms/');
        }

        if (notification.data.model_type === 'task') {
          if (notification.data.metadata.taskable_type === 'client' || notification.data.metadata.taskable_type === 'account') {
            this.$router.push(
              '/contacts/' + notification.data.metadata.taskable_uuid + '/tasks/' + notification.data.model_uuid
            );
          }

          if (notification.data.metadata.taskable_type === 'case') {
            this.$router.push(
              '/cases/' + notification.data.metadata.taskable_uuid + '/tasks/' + notification.data.model_uuid
            );
          }

          if (notification.data.metadata.taskable_type === 'plan') {
            this.$router.push(
              '/plans/' + notification.data.metadata.taskable_uuid + '/tasks/' + notification.data.model_uuid
            );
          }

          if (notification.data.metadata.taskable_type === 'task') {
            this.$router.push(`/contacts/${notification.data.metadata.taskable_uuid}/tasks`);
          } else {
            this.$nuxt.$emit('notification', "Notification type hasn't been recognised");
          }
        }

        if (notification.data.type === 'note.mention') {
          if (notification.data?.navigator?.model_type === 'client' || notification.data?.navigator?.model_type === 'account') {
            this.$router.push('/contacts/' + notification.data?.navigator?.model_uuid + '/notes');
          }

          if (notification.data?.navigator?.model_type === 'circle') {
            this.$router.push('/circle/' + notification.data?.navigator?.model_uuid + '/notes');
          }

          if (notification.data?.navigator?.model_type === 'case') {
            this.$router.push('/cases/' + notification.data?.navigator?.model_uuid + '/notes');
          }

          if (notification.data?.navigator?.model_type === 'plan') {
            this.$router.push('/plans/' + notification.data?.navigator?.model_uuid + '/notes');
          }

          if (notification.data.navigator?.model_type === 'statement') {
            this.$router.push('/business/income-reconciliation/statement/' + notification.data?.navigator?.model_uuid + '/notes');
          }

          if (notification.data.navigator?.model_type === 'expectation') {
            this.$router.push('/business/income-reconciliation/expectations/' + notification.data?.navigator?.model_uuid);
          }

          if (notification.data.navigator?.model_type === 'statement_transaction') {
            this.$router.push('/business/income-reconciliation/provider-statement-transactions/' + notification.data?.navigator?.model_uuid);
          }

          if (notification.data.navigator?.model_type === 'bank_transaction') {
            this.$router.push('/business/income-reconciliation/bank-transactions/' + notification.data?.navigator?.model_uuid);
          }

          if (notification.data?.navigator?.model_type === 'task') {
            if (notification.data.navigator?.data?.taskable_type === 'client' || notification.data.navigator?.data?.taskable_type === 'account') {
              this.$router.push(
                '/contacts/' +
                notification.data.navigator?.data.taskable_uuid +
                '/tasks/' +
                notification.data.navigator?.model_uuid
              );
            }
            if (notification.data.navigator?.data?.taskable_type === 'case') {
              this.$router.push(
                '/cases/' +
                notification.data.navigator?.data.taskable_uuid +
                '/tasks/' +
                notification.data.navigator?.model_uuid
              );
            }
            if (notification.data.navigator?.data?.taskable_type === 'plan') {
              this.$router.push(
                '/plans/' +
                notification.data.navigator?.data.taskable_uuid +
                '/tasks/' +
                notification.data.navigator?.model_uuid
              );
            }
          }
        }

        if (notification.data.model_type === 'message') {
          commit('SET_CONVERSATION_UUID', notification.data.navigator.model_uuid);
        }

        if (notification.data.type === 'message.mention') {
          if (notification.data.model_type === 'client' || notification.data.model_type === 'account') {
            this.$router.push('/contacts/' + notification.data.model_uuid + '/notes');
          }

          if (notification.data.model_type === 'case') {
            this.$router.push('/cases/' + notification.data.model_uuid + '/notes');
          }

          if (notification.data.model_type === 'plan') {
            this.$router.push('/plans/' + notification.data.model_uuid + '/notes');
          }

          if (notification.data.model_type === 'message') {
            commit('SET_CONVERSATION_UUID', notification.data.navigator.model_uuid);
          }
        }

        if (notification.data.type === 'message.new') {
          commit('SET_CONVERSATION_UUID', notification.model_uuid);
        }

        if (notification.data.model_type === 'conversation') {
          commit('SET_CONVERSATION_UUID', notification.data.model_uuid);
        }
        if (notification.data.type === 'fact-find.completed') {
          this.$router.push('/contacts/' + notification.data.metadata.client_uuid + '/factfind');
        }

        if (notification.data.model_type === 'document') {
          this.$router.push('/business/docs/' + notification.data.model_uuid);
        }

        if (notification.data.model_type === 'signature_transaction') {
          signatureAPI.getSignature(notification.data.model_uuid).then((rsp) => {
            intentManager.redirectWithIntent(
              'viewSignatureFromNotification',
              {
                signature: rsp.data.data,
              },
              this.$router,
              '/business/signatures/'
            );
          });
        }

        if (notification.data.model_type === 'fca_number') {
          this.$router.push('/settings/fca');
        }

        if (notification.data.model_type === 'report') {
          this.$router.push('/business/reporting/' + notification.data.model_uuid);
        }

        if (notification.data.model_type === 'import') {
          this.$router.push('/business/imports/' + notification.data.model_uuid);
        }
      } else {
        if (notification.data.model_type === 'task') {
          this.$router.push('/portal/my-tasks');
          dispatch('redirectToTask', notification);
        }

        if (notification.data.model_type === 'form') {
          this.$router.push('/portal/my-forms/');
        }
        if (notification.data.type === 'note.mention') {
          if (notification.data?.navigator?.model_type === 'task') {
            const pushNotification = notification;
            dispatch('redirectToTask', pushNotification);
          }
        }
        if (notification.data.model_type === 'document') {
          this.$router.push('/portal/documents');
        }
        if (notification.data.model_type === 'file') {
          this.$router.push('/portal/documents?f=' + notification?.data?.folder_uuid);
        }
        if (notification.data.model_type === 'fact_find_request') {
          this.$router.push('/portal/requests/request/' + notification.data.model_uuid);
        }
        if (notification.data.model_type === 'document') {
          this.$router.push('/portal/documents?f=' + notification?.data?.folder_uuid);
        }
        if (notification.data.type === 'message.new') {
          commit('SET_CONVERSATION_UUID', notification.data.model_uuid);
        }
        if (notification.data.type === 'message.mention') {
          commit('SET_CONVERSATION_UUID', notification.data.navigator.model_uuid);
        }
        if (notification.data.type === 'conversation') {
          commit('SET_CONVERSATION_UUID', notification.data.model_uuid);
        }
        if (notification.data.type === 'conversation.new') {
          commit('SET_CONVERSATION_UUID', notification.data.model_uuid);
        }
      }
    },

    modalClose({ commit }) {
      commit('SET_NOTIFICATION_POP_UP_CLOSE');
    },
    modalOpen({ commit }) {
      commit('SET_NOTIFICATION_POP_UP_OPEN');
    },

    markAllAsRead({ commit, dispatch }) {
      notificationAPI
        .markAllAsRead()
        .then((rsp) => {
          commit('MARK_ALL_NOTIFICATION_AS_READ');
          dispatch('refreshNotifications');
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    },

    unsetConversationUuid({ commit }) {
      commit('UNSET_CONVERSATION_UUID');
    },

    markAsUnread({ commit }, notification) {
      notificationAPI.markAsUnread(notification.uuid).then((rsp) => {
        commit('MARK_AS_UNREAD', notification);
        dispatch('refreshNotifications');
      });
    },

    markAsRead({ commit, dispatch }, notification) {
      notificationAPI.markAsRead(notification.uuid).then((rsp) => {
        commit('MARK_AS_READ', notification);
        dispatch('refreshNotifications');
      });
    },

    deleteNotification({ commit, dispatch }, notification) {
      notificationAPI.deleteNotification(notification.uuid).then((rsp) => {
        commit('DELETE_NOTIFICATION', notification);
        dispatch('refreshNotifications');
      });
    },
  },

  mutations: {
    SET_INCLUDE_READ(state, data) {
      state.include_read = data;
    },

    SET_NOTIFICATION_POP_UP_CLOSE(state) {
      state.notificationPopUpClose = true;
    },

    SET_NOTIFICATION_POP_UP_OPEN(state) {
      state.notificationPopUpClose = false;
    },

    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications;
    },

    SET_CONVERSATION_UUID(state, uuid) {
      state.openConversationUuid = uuid;
    },

    UNSET_CONVERSATION_UUID(state) {
      state.openConversationUuid = null;
    },

    SET_META(state, meta) {
      state.meta = meta;
    },

    SET_UNREAD_COUNT(state, data) {
      state.unread = data;
    },

    MARK_ALL_NOTIFICATION_AS_READ(state) {
      state.notifications.forEach((notification) => {
        notification.read_at = new Date();
      });
      state.unread = 0;
    },

    MARK_AS_UNREAD(state, notification) {
      const index = state.notifications.findIndex((n) => n.uuid === notification.uuid);

      if (index === -1) {
        return;
      }

      state.notifications[index].read_at = null;
      state.unread++;
    },

    MARK_AS_READ(state, notification) {
      const index = state.notifications.findIndex((n) => n.uuid === notification.uuid);

      if (index === -1) {
        return;
      }

      state.notifications[index].read_at = new Date();
      if (state.unread > 0) {
        state.unread--;
      }
    },

    DELETE_NOTIFICATION(state, notification) {
      const index = state.notifications.findIndex((n) => n.uuid === notification.uuid);

      if (index === -1) {
        return;
      }

      state.notifications.splice(index, 1);
    },
  },
};
