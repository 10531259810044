
import navigatorAPI from '@/api/navigator';
import emailAPI from '@/api/emails';

export default {
  name: 'ResultItem',

  props: {
    result: {
      type: Object,
      required: true,
    },
  },

  computed: {
    resultType() {
      const modelType = this.result.model.type === 'account' ? this.result.model.account_type : this.result.model.type;
      return this.$helpers.sentencify(modelType);
    },

    resultTitle() {
      if (this.result.model.type === 'account') {
        return this.result.model.name;
      } else if (this.result.model.type === 'plan') {
        return this.result.model.client_name;
      } else {
        return null;
      }
    },
  },

  methods: {
    redirectTo(result) {
      if (result.model.type === 'account') {
        this.$router.push('/contacts/' + result.model.uuid);
      }

      if (result.model.type === 'circle') {
        this.$router.push('/circle/' + result.model.uuid);
      }

      if (this.result.model.type === 'plan') {
        this.$router.push('/plans/' + this.result.model.uuid);
      }

      if (result.model.type === 'message') {
        this.$nuxt.$emit('openConvo', result.model.parent_uuid);
      }

      if (result.model.type === 'mailbox_message') {
        emailAPI.getMessageFromMailbox(result.model.uuid).then((rsp) => {
          let threadId = null;
          let messageId = null;
          let contactId = null;
          if (rsp.data.data.descendants.length > 0) {
            threadId = rsp.data.data.descendants[rsp.data.data.descendants.length - 1].uuid;
            messageId = rsp.data.data.uuid;
          } else {
            threadId = rsp.data.data.uuid;
          }
          if (result.model.related_account_uuids.length > 0) {
            contactId = result.model.related_account_uuids[result.model.related_account_uuids.length - 1];
          } else {
            contactId = result.model.related_account_uuids[0];
          }
          this.$router.push({
            path: '/contacts/' + contactId + '/emails/',
            query: { thread_id: threadId, message_id: messageId },
          });
        });
      }

      if (result.model.type === 'note') {
        if (result.model.parent_type === 'client') {
          this.$router.push('/contacts/' + result.model.parent_uuid + '/notes');
        }
        if (result.model.parent_type === 'case') {
          this.$router.push('/cases/' + result.model.parent_uuid + '/notes');
        }
        if (result.model.parent_type === 'plan') {
          this.$router.push('/plans/' + result.model.parent_uuid + '/notes');
        }
        if (result.model.parent_type === 'task') {
          this.$router.push('/task/' + result.model.parent_uuid + '/notes');
        }
      }

      if (result.model.type === 'case') {
        this.$router.push('/cases/' + result.model.uuid);
      }

      if (result.model.type === 'conversation') {
        this.$nuxt.$emit('openConvo', result.model.uuid);
      }

      if (result.model.type === 'file') {
        if (result.model.parent_type === 'client') {
          this.$router.push('/contacts/' + result.model.parent_uuid + '/documents');
        }
        if (result.model.parent_type === 'case') {
          this.$router.push('/cases/' + result.model.parent_uuid + '/documents');
        }
        if (result.model.parent_type === 'message') {
          // get the message, then grab the conversation from that
          navigatorAPI.getNavigator('message', result.model.parent_uuid).then((rsp) => {
            return '/conversations/' + rsp.data.data.conversation_uuid;
          });
        }
      }

      if (this.result.model.type === 'folder') {
        if (this.result.model.parent_type === 'client') {
          this.$router.push('/contacts/' + this.result.model.parent_uuid + '/documents/');
        }
        if (this.result.model.parent_type === 'prospect') {
          this.$router.push('/prospects/' + this.result.model.parent_uuid + '/documents/');
        }
        if (this.result.model.parent_type === 'case') {
          this.$router.push('/cases/' + this.result.model.parent_uuid + '/documents/');
        }
      }

      this.$emit('closePanel');
    },

    pathname(path) {
      return path ? path.split('/')[0] : null;
    },
  },
};
