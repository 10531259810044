
import circlesAPI from '~/api/circles';

export default {
  name: 'CircleSelector',

  props: {
    value: {
      type: Array,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: 'Select circle',
    },
    labelSize: {
      type: String,
      required: false,
      default: '',
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      circles: [],
      loading: false,
      v: [],
      searchQuery: '',
    };
  },
  mounted() {
    if (this.value) {
      this.v = this.value;
    }
  },
  methods: {
    onSearchChange(val) {
      this.searchQuery = val;
      this.applyFilter(val);
    },
    async applyFilter(val) {
      if (val.length >= 3) {
        this.loading = true;

        const params = {
          ...this.getDefaultParams(),
          'filter[name]': val,
          include: 'accounts,firm,groups',
        };

        const rsp = await this.grabData(params);
      } else if (!val.length) {
        this.circles = [];
      }
    },
    grabData(params) {
      circlesAPI.allPaged(params).then((rsp) => {
        this.circles = rsp;
        this.loading = false;

        if (this.value) {
          this.v = this.circles.filter((item) => {
            return this.value.includes(item);
          });
        }
      });
    },
    getDefaultParams() {
      return { per_page: 200 };
    },
  },
  watch: {
    v: {
      deep: true,
      handler(new_val) {
        if (new_val === null) {
          this.$emit('input', null);
        } else {
          this.$emit('input', new_val);
        }
      },
    },
  },
};
