export default {
  getAccounts: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/mailboxes', data),

  createMailbox: (data) => $nuxt.$axios.post($nuxt.$api.host + '/mailboxes?type=' + data),

  updateMailbox: (mailbox_uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/mailboxes/' + mailbox_uuid, data),

  deleteMailbox: (data) => $nuxt.$axios.delete($nuxt.$api.host + '/mailboxes/' + data),

  getMessageFromMailbox: (message_uuid) => $nuxt.$axios.get($nuxt.$api.host + '/mailboxes/message/' + message_uuid),

  getMailboxFolders: (mailbox_uuid) => $nuxt.$axios.get($nuxt.$api.host + '/mailboxes/' + mailbox_uuid + '/folders'),

  getMessages: (mailbox_uuid, data) => $nuxt.$axios.get($nuxt.$api.host + '/client/' + mailbox_uuid + '/emails', data),
  getCircleMessages: (circle_uuid, data) =>
    $nuxt.$axios.get($nuxt.$api.host + '/circles/' + circle_uuid + '/emails', data),

  getAuthentication: (mailbox_uuid) =>
    $nuxt.$axios.get($nuxt.$api.host + '/mailboxes/' + mailbox_uuid + '/reauthenticate'),

  deleteThreadMessage: (message_uuid, data) =>
    $nuxt.$axios.delete($nuxt.$api.host + '/mailboxes/message/' + message_uuid, data),

  deleteThread: (message_uuid, data) =>
    $nuxt.$axios.delete($nuxt.$api.host + '/mailboxes/message/' + message_uuid + '/thread', data),
};
