
import { mapGetters, mapState } from 'vuex';
import conversationsAPI from '@/api/conversations';
import filesize from 'filesize';
import checkForErrors from '@/helpers/checkForErrors';
import VueContenteditableInput from 'vue-contenteditable-input';
import accountAPI from '@/api/account';
import loginsAPI from '@/api/logins';

export default {
  name: 'conversation-thread',

  components: {
    VueContenteditableInput,
  },

  props: {
    participants: {
      type: Array,
      required: false,
    },
    conversation_uuid: {
      type: String,
      required: true,
    },
    depth_uuid: {
      type: String | null,
      required: true,
    },
  },

  data() {
    return {
      groups: '',
      name: '',
      toggleView: true,
      dataChanged: false,
      conversation: {
        participants: [],
        case: {},
        pinned: false,
      },
      editThread: false,
      loading: true,
      rawMessageList: [],
      disableSend: false,
      newMessage: {
        contents: '',
        files: [],
      },
      editConversation: {
        name: '',
        participants: [],
      },
      newParticipants: {
        participants: [],
      },
      addParticipantType: null,
      newClientsWithoutLogins: [],
      existingClientsWithoutLoginsUuids: [],
      buttonChoices: [
        {
          label: 'Employee',
          value: 'employee',
        },
        {
          label: 'Client',
          value: 'client',
        },
        {
          label: 'Introducer',
          value: 'external',
        },
      ],
    };
  },

  computed: {
    ...mapGetters('user', ['currentAccountUuid', 'isClient', 'isEmployee', 'isSeniorEmployee']),
    ...mapState('user', ['currentLogin']),

    remainingNames() {
      let reduced = this.conversation.participants.slice(2);
      let html = reduced.map((item) => {
        return '<li><nuxt-link :to="routeTo(item)">' + item.name + '</nuxt-link></li>';
      });
      return '<ul>' + html.join(' ') + '</ul>';
    },

    remaining() {
      return this.conversation.participants.length - 2;
    },

    generateBackButton() {
      return '/conversations';
    },

    getParticipantUuids() {
      return this.conversation.participants ? this.conversation.participants.map((item) => item.uuid) : [];
    },

    messages() {
      // Order the raw message list by message sent timestamp and group within dates.
      return this.rawMessageList
        .sort((a, b) => (a.created_at < b.created_at ? -1 : a.created_at > b.created_at ? 1 : 0))
        .reduce((r, message) => {
          r[message.date] = [...(r[message.date] || []), message];
          return r;
        }, {});
    },

    getClientName() {
      let participant = this.conversation.participants.filter((item) => {
        return item.uuid === this.depth_uuid;
      });

      if (participant.length === 0) {
        return null;
      }
      return participant[0].name;
    },

    isConversationAdmin() {
      return Boolean(this.conversation.is_admin) || this.isSeniorEmployee;
    },
  },

  mounted() {
    this.getConversation(this.conversation_uuid);
    this.getClient();
    this.resize();
    window.onresize = this.resize;
    this.$nuxt.$on('handleFiles', this.handleFiles);
  },

  beforeRouteUpdate(to, from, next) {
    this.getConversation(to.params.id);
    next();
  },

  beforeDestroy() {
    if (this.conversation) {
      this.$echo.leave('conversation.' + this.conversation.uuid);
    }
    this.$nuxt.$off('handleFiles');
  },

  methods: {
    getClient() {
      if (this.isClient) {
        accountAPI.getContact(this.currentAccountUuid).then((rsp) => {
          let client = rsp.data.data;
          let groups = client.groups.map((group) => group.uuid);
          this.groups = groups.join(',');
        });
      } else {
        return null;
      }
    },

    getRouteToCase() {
      this.$router.push('/cases/' + this.conversation.case.uuid);
      this.closeModal();
    },

    closeModal() {
      this.$emit('close');
    },

    resize() {
      if (window.innerWidth >= 1000) {
        this.toggleView = true;
      }
      if (window.innerWidth <= 1000) {
        this.toggleView = false;
      }
    },

    newEntry() {
      this.dataChanged = true;
    },

    saveNewValue(conversation) {
      this.editConversation.name = conversation.target.innerText;
    },

    updatePinned() {
      conversationsAPI.updatePin(this.conversation_uuid, { pin: !this.conversation.pinned }).then((rsp) => {
        this.conversation = rsp.data.data;
        this.$emit('toggled');
        this.$nuxt.$emit('refreshInbox');
        this.$nuxt.$emit('notification', 'Pin updated');
      });
    },

    getConversation(uuid) {
      if (this.conversation) {
        this.$echo.leave('conversation.' + this.conversation?.uuid);
      }

      conversationsAPI.getConversation(uuid, { params: { include: 'case' } }).then((rsp) => {
        this.conversation = rsp.data.data;
        this.title = rsp.data.data.name;
        this.$echo
          .join('conversation.' + this.conversation.uuid)
          .listen('Conversation\\NewMessage', (e) => {
            this.grabMessage(e);
          })
          .listen('Filesystem\\FileUpdated', (file) => {
            this.fileUpdated(file);
          });

        this.getMessages();
        this.$nuxt.$emit('refreshInbox');
      });
    },

    getMessages() {
      this.loading = true;
      this.$nuxt.$loading.start();
      conversationsAPI.getAllMessages(this.conversation.uuid).then((rsp) => {
        this.rawMessageList = rsp;
        this.loading = false;
        this.$nuxt.$loading.finish();
      });
    },

    sendMessage() {
      this.disableSend = true;
      let data = {
        contents: this.newMessage.contents,
        files: this.newMessage.files.map((file) => file.uuid),
      };
      conversationsAPI
        .createMessage(this.conversation.uuid, data)
        .then((rsp) => {
          this.rawMessageList.push(rsp.data.data);
          this.$nuxt.$emit('refreshThread');
          this.$nuxt.$emit('refreshInbox');
          this.$refs.clear.clearContents();
          this.newMessage = {
            contents: '',
            files: [],
          };
          this.disableSend = false;
          this.$refs.formErrorPanel.clear();
        })
        .catch((err) => {
          if (err.response.status === 403) {
            this.$nuxt.$emit('notification', err.response.data.message, 'warning', true);
          }
          checkForErrors.process(err, this.$refs.formErrorPanel);
          this.disableSend = false;
        });
    },

    startEdit() {
      this.editThread = true;
      this.editConversation = this.conversation;
    },

    editConvo() {
      conversationsAPI
        .updateConversation(this.editConversation.uuid, { name: this.editConversation.name })
        .then((rsp) => {
          this.$nuxt.$emit('notification', 'Conversation updated', 'alert', true);
          this.$nuxt.$emit('refreshInbox');
          this.editThread = false;
        });
    },

    closeEditConvo() {
      delete this.editConversation.name;
      this.$nuxt.$emit('refreshThread');
      this.$nuxt.$emit('refreshInbox');
      this.getConversation(this.conversation_uuid);
      this.editThread = false;
    },

    viewParticipants() {
      this.$refs.participants.open();
    },

    getRouteToParticipant(participant) {
      return '/contacts/' + participant.uuid;
    },

    removeParticipant(participant) {
      this.$nuxt.$emit('confirm', {
        message: 'Are you sure you would like to remove ' + participant.name + ' from this conversation?',
        callback: async () => {
          await conversationsAPI.removeParticipants(this.conversation.uuid, {
            data: { participants: [participant.uuid] },
          });

          this.conversation.participants = this.conversation.participants.filter((item) => {
            return item.uuid !== participant.uuid;
          });
          this.$nuxt.$emit('refreshInbox');
          this.$refs.participants.close();
        },
      });
    },

    addNewParticipants() {
      let newClients = this.newParticipants?.participants?.map((item) => item.uuid);
      conversationsAPI
        .addParticipants(this.conversation.uuid, { participants: newClients })
        .then((rsp) => {
          this.getConversation(this.conversation_uuid);
          this.$nuxt.$emit('refreshThread');
          this.$refs.participants.close();
          this.$nuxt.$emit(
            'notification',
            this.newParticipants.participants.length + ' new participants added',
            'alert',
            true
          );
        })
        .catch((err) => {
          checkForErrors.process(err, this.$refs.formErrorPanel);
        });
    },

    archive() {
      this.$nuxt.$emit('confirm', {
        message: 'Are you sure you would like to archive this conversation?',
        callback: async () => {
          await conversationsAPI.archiveConversation(this.conversation.uuid);

          this.$nuxt.$emit('notification', 'Conversation archived');
          this.$nuxt.$emit('refreshInbox');
          this.$nuxt.$emit('viewInbox');
        },
      });
    },

    unarchive() {
      this.$nuxt.$emit('confirm', {
        message: 'Are you sure you would like to unarchive this conversation?',
        callback: async () => {
          await conversationsAPI.unarchiveConversation(this.conversation.uuid);

          this.$nuxt.$emit('notification', 'Conversation unarchived');
          this.$nuxt.$emit('refreshInbox');
          this.$nuxt.$emit('viewInbox');
        },
      });
    },

    removeMe() {
      this.$nuxt.$emit('confirm', {
        message: 'Are you sure you would like to remove this conversation?',
        callback: async () => {
          await conversationsAPI.removeConversation(this.conversation.uuid);

          this.$nuxt.$emit('notification', 'Conversation deleted', 'alert', true);
          this.$nuxt.$emit('refreshInbox');
          this.$nuxt.$emit('refreshConversations');
          this.$nuxt.$emit('viewInbox');
        },
      });
    },

    handleFiles(files) {
      this.newMessage.files = this.newMessage.files.concat(files);
    },

    getSize(size) {
      return filesize(size);
    },

    fileUpdated(file) {
      // First find the message with the documentable uuid.

      let messageIndex = this.rawMessageList.findIndex((m) => m.uuid === file.documentable_uuid);

      // If cannot find message then return.

      if (messageIndex === -1) {
        return;
      }

      // Now find the file within the message and update if found.

      let fileIndex = this.rawMessageList[messageIndex].files.findIndex((f) => f.uuid === file.uuid);

      if (fileIndex === -1) {
        return;
      }

      this.rawMessageList[messageIndex].files.splice(fileIndex, 1, file);
    },

    grabMessage(event) {
      if (event.author === this.currentAccountUuid) {
        return;
      }

      conversationsAPI.getMessage(event.conversation, event.message).then((rsp) => {
        this.rawMessageList.push(rsp.data.data);
      });
    },

    shouldGroupTogether(message, previousMessage) {
      // If no previous message within date group or previous messsage author is different then do not group.

      if (previousMessage == null) {
        return false;
      }

      if (message.author.uuid !== previousMessage.author.uuid) {
        return false;
      }

      // Else only group message if it was sent within one minute of the previous message.

      const messageSent = this.$dayjs(message.created_at);
      const previousMessageSent = this.$dayjs(previousMessage.created_at);

      return messageSent.diff(previousMessageSent, 'minutes') < 1;
    },
    async getClientsWithoutLogins(participants, isNewParticipant = false) {
      if (!Array.isArray(participants) || participants.length === 0) {
        return [];
      }

      try {
        const clientParticipants = participants.filter((p) => p.type === 'client');
        const clientLoginsWithParticipants = await Promise.all(
          clientParticipants.map(async (participant) => ({
            participant,
            loginData: await loginsAPI.getAll(participant.uuid),
          }))
        );

        return clientLoginsWithParticipants
          .filter(({ loginData }) => loginData.data.data.length === 0)
          .map(({ participant }) => participant);
      } catch (error) {
        this.$nuxt.$emit('notification', 'Unable to check for clients without logins', 'warning');

        return [];
      }
    },
  },

  watch: {
    $route(to, from) {
      this.getConversation(to.params.id);
    },

    addParticipantType(new_val, old_val) {
      this.newParticipants.participants = [];
    },
    'conversation.participants': {
      handler: async function (val) {
        this.existingClientsWithoutLoginsUuids = (await this.getClientsWithoutLogins(val))?.map(
          (client) => client.uuid
        );
      },
      deep: true,
    },
    'newParticipants.participants': {
      handler: async function (val) {
        this.newClientsWithoutLogins = await this.getClientsWithoutLogins(val);
      },
      deep: true,
    },
  },
};
