import paginator from '../helpers/paginator';

export default {
  allForClient: (client_uuid, data) => $nuxt.$axios.get($nuxt.$api.host + '/client/' + client_uuid + '/plans', data),
  allPaginatedForClient: async (client_uuid, params = null) =>
    await paginator.grabAllPages($nuxt.$api.host + '/client/' + client_uuid + '/plans', {}, params, 500),
  all: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/plans', data),
  allPaginated: async (params = null) => await paginator.grabAllPages($nuxt.$api.host + '/plans', {}, params, 500),
  create: (data, headers = null) => $nuxt.$axios.post($nuxt.$api.host + '/plans', data, headers),
  get: (plan_uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid, data),
  update: (plan_uuid, data, headers = null) => $nuxt.$axios.put($nuxt.$api.host + '/plans/' + plan_uuid, data, headers),
  remove: (plan_uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/plans/' + plan_uuid),

  allSubAccounts: (plan_uuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid + '/sub-accounts', data),
  createSubAccount: (plan_uuid, data) =>
    $nuxt.$axios.post($nuxt.$api.host + '/plans/' + plan_uuid + '/sub-accounts', data),
  getSubAccount: (plan_uuid, subAccountUuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid + '/sub-accounts/' + subAccountUuid, data),
  updateSubAccount: (plan_uuid, subAccountUuid, data) =>
    $nuxt.$axios.put($nuxt.$api.host + '/plans/' + plan_uuid + '/sub-accounts/' + subAccountUuid, data),
  removeSubAccount: (plan_uuid, subAccountUuid) =>
    $nuxt.$axios.delete($nuxt.$api.host + '/plans/' + plan_uuid + '/sub-accounts/' + subAccountUuid),

  createValuation: (planUuid, subAccountUuid, data) =>
    $nuxt.$axios.post($nuxt.$api.host + '/plans/' + planUuid + '/sub-accounts/' + subAccountUuid + '/valuations', data),

  allContributions: (plan_uuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid + '/contributions', data),
  allContributionsForSubAccount: (plan_uuid, subAccountUuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid + '/contributions/' + subAccountUuid, data),
  createContribution: (plan_uuid, subAccountUuid, data) =>
    $nuxt.$axios.post(
      $nuxt.$api.host + '/plans/' + plan_uuid + '/sub-accounts/' + subAccountUuid + '/contributions',
      data
    ),
  getContribution: (plan_uuid, subAccountUuid, contributionUuid, data = null) =>
    $nuxt.$axios.get(
      $nuxt.$api.host +
        '/plans/' +
        plan_uuid +
        '/sub-accounts/' +
        subAccountUuid +
        '/contributions/' +
        contributionUuid,
      data
    ),
  updateContribution: (plan_uuid, subAccountUuid, contributionUuid, data) =>
    $nuxt.$axios.put(
      $nuxt.$api.host +
        '/plans/' +
        plan_uuid +
        '/sub-accounts/' +
        subAccountUuid +
        '/contributions/' +
        contributionUuid,
      data
    ),
  removeContribution: (plan_uuid, subAccountUuid, contributionUuid) =>
    $nuxt.$axios.delete(
      $nuxt.$api.host + '/plans/' + plan_uuid + '/sub-accounts/' + subAccountUuid + '/contributions/' + contributionUuid
    ),

  allWithdrawals: (plan_uuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid + '/withdrawals', data),
  allWithdrawalsForSubAccount: (plan_uuid, subAccountUuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid + '/withdrawals/' + subAccountUuid, data),
  createWithdrawal: (plan_uuid, subAccountUuid, data) =>
    $nuxt.$axios.post(
      $nuxt.$api.host + '/plans/' + plan_uuid + '/sub-accounts/' + subAccountUuid + '/withdrawals',
      data
    ),
  getWithdrawal: (plan_uuid, subAccountUuid, withdrawal, data = null) =>
    $nuxt.$axios.get(
      $nuxt.$api.host + '/plans/' + plan_uuid + '/sub-accounts/' + subAccountUuid + '/withdrawals/' + withdrawal,
      data
    ),
  updateWithdrawal: (plan_uuid, subAccountUuid, withdrawal, data) =>
    $nuxt.$axios.put(
      $nuxt.$api.host + '/plans/' + plan_uuid + '/sub-accounts/' + subAccountUuid + '/withdrawals/' + withdrawal,
      data
    ),
  removeWithdrawal: (plan_uuid, subAccountUuid, withdrawal) =>
    $nuxt.$axios.delete(
      $nuxt.$api.host + '/plans/' + plan_uuid + '/sub-accounts/' + subAccountUuid + '/withdrawals/' + withdrawal
    ),

  allHoldings: (plan_uuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid + '/holdings', data),
  allHoldingsForSubAccount: (plan_uuid, subAccountUuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid + '/holdings/' + subAccountUuid, data),
  createHolding: (plan_uuid, subAccountUuid, data) =>
    $nuxt.$axios.post($nuxt.$api.host + '/plans/' + plan_uuid + '/sub-accounts/' + subAccountUuid + '/holdings', data),
  getHolding: (plan_uuid, subAccountUuid, holding, data = null) =>
    $nuxt.$axios.get(
      $nuxt.$api.host + '/plans/' + plan_uuid + '/sub-accounts/' + subAccountUuid + '/holdings/' + holding,
      data
    ),
  updateHolding: (plan_uuid, subAccountUuid, holding, data) =>
    $nuxt.$axios.put(
      $nuxt.$api.host + '/plans/' + plan_uuid + '/sub-accounts/' + subAccountUuid + '/holdings/' + holding,
      data
    ),
  removeHolding: (plan_uuid, subAccountUuid, holding) =>
    $nuxt.$axios.delete(
      $nuxt.$api.host + '/plans/' + plan_uuid + '/sub-accounts/' + subAccountUuid + '/holdings/' + holding
    ),

  dataForGraph: (plan_uuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid + '/reporting/valuations', data),
  dataForGraphForSubAccounts: (plan_uuid, sub_account_uuid = null, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid + '/reporting/valuations/' + sub_account_uuid, data),

  allTransactions: (plan_uuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid + '/transaction', data),
  createTransaction: (plan_uuid, data) =>
    $nuxt.$axios.post($nuxt.$api.host + '/plans/' + plan_uuid + '/transaction', data),
  getTransaction: (plan_uuid, transaction_uuid) =>
    $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid + '/transaction/' + transaction_uuid),
  updateTransaction: (plan_uuid, transaction_uuid, data) =>
    $nuxt.$axios.put($nuxt.$api.host + '/plans/' + plan_uuid + '/transaction/' + transaction_uuid, data),
  removeTransaction: (plan_uuid, transaction_uuid) =>
    $nuxt.$axios.delete($nuxt.$api.host + '/plans/' + plan_uuid + '/transaction/' + transaction_uuid),

  allTransactionSchedules: (plan_uuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid + '/transaction-schedule', data),
  createTransactionSchedule: (plan_uuid, data) =>
    $nuxt.$axios.post($nuxt.$api.host + '/plans/' + plan_uuid + '/transaction-schedule', data),
  getTransactionSchedule: (plan_uuid, transaction_schedule_uuid) =>
    $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid + '/transaction-schedule/' + transaction_schedule_uuid),
  updateTransactionSchedule: (plan_uuid, transaction_schedule_uuid, data) =>
    $nuxt.$axios.put(
      $nuxt.$api.host + '/plans/' + plan_uuid + '/transaction-schedule/' + transaction_schedule_uuid,
      data
    ),
  removeTransactionSchedule: (plan_uuid, transaction_schedule_uuid) =>
    $nuxt.$axios.delete($nuxt.$api.host + '/plans/' + plan_uuid + '/transaction-schedule/' + transaction_schedule_uuid),

  createPlanCover: (plan_uuid, data) => $nuxt.$axios.post(`${$nuxt.$api.host}/plans/${plan_uuid}/cover`, data),
  updatePlanCover: (plan_uuid, cover_uuid, data) =>
    $nuxt.$axios.put(`${$nuxt.$api.host}/plans/${plan_uuid}/cover/${cover_uuid}`, data),
  deletePlanCover: (plan_uuid, cover_uuid) =>
    $nuxt.$axios.delete(`${$nuxt.$api.host}/plans/${plan_uuid}/cover/${cover_uuid}`),

  createPlanClaim: (plan_uuid, data) => $nuxt.$axios.post(`${$nuxt.$api.host}/plans/${plan_uuid}/claim`, data),
  updatePlanClaim: (plan_uuid, claim_uuid, data) =>
    $nuxt.$axios.put(`${$nuxt.$api.host}/plans/${plan_uuid}/claim/${claim_uuid}`, data),
  deletePlanClaim: (plan_uuid, claim_uuid) =>
    $nuxt.$axios.delete(`${$nuxt.$api.host}/plans/${plan_uuid}/claim/${claim_uuid}`),

  getDocuments: (plan_uuid, data) => $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid + '/documents', data),

  createSubScheme: (plan_uuid, data) =>
    $nuxt.$axios.post(`${$nuxt.$api.host}/plans/${plan_uuid}/sub-group-schemes`, data),

  allSubSchemesForPlan: (plan_uuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid + '/sub-group-schemes', data),

  getProviderLogs: (plan_uuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/plans/' + plan_uuid + '/provider-feed-logs', data),
};
