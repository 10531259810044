export default {
  getUploadUrl: () => $nuxt.$api.host + '/upload-files',
  uploadFiles: (data) => $nuxt.$axios.post($nuxt.$api.host + '/upload-files/create', data),
  updateFile: (file_uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/file/' + file_uuid, data),
  assignFiles: (data) => $nuxt.$axios.post($nuxt.$api.host + '/file', data),
  getFolder: (folder_uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/folder/' + folder_uuid, data),
  createFolder: (data) => $nuxt.$axios.post($nuxt.$api.host + '/folder', data),
  deleteFile: (file_uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/file/' + file_uuid),
  editFile: (file_uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/file/' + file_uuid, data),
  updateFolder: (folder_uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/folder/' + folder_uuid, data),
  updatePermissions: (folder_uuid, data) => $nuxt.$axios.post($nuxt.$api.host + '/folder/' + folder_uuid + '/permissions/', data),
  deleteFolder: (folder_uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/folder/' + folder_uuid),
  getFolderTree: (folder_uuid) => $nuxt.$axios.get($nuxt.$api.host + '/folder/' + folder_uuid + '/tree'),
  getFlatFolder: (folder_uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/folder/' + folder_uuid + '/files', data),

  // Search files of a model and UUID.
  searchFiles: (model, uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/files/' + model + '/' + uuid, data),
}
