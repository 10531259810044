import paginator from '@/helpers/paginator';

export default {
  getAllForms: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/form', data),
  getSingleForm: (form_uuid, data = null) => $nuxt.$axios.get($nuxt.$api.host + '/form/' + form_uuid, data),
  getAllLinkedQuestions: (data = null) => $nuxt.$axios.get($nuxt.$api.host + '/linked-form-questions', data),
  getAllCustomFieldQuestions: (data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/custom-linked-form-questions', data),
  createForm: (data) => $nuxt.$axios.post($nuxt.$api.host + '/form/', data),
  duplicateForm: (uuid) => $nuxt.$axios.post($nuxt.$api.host + '/form/' + uuid + '/duplicate'),
  updateForm: (uuid, data) => $nuxt.$axios.put($nuxt.$api.host + '/form/' + uuid, data),
  deleteForm: (uuid) => $nuxt.$axios.delete($nuxt.$api.host + '/form/' + uuid),
  publishForm: (uuid) => $nuxt.$axios.post($nuxt.$api.host + '/form/' + uuid + '/publish'),
  unPublishForm: (uuid) => $nuxt.$axios.post($nuxt.$api.host + '/form/' + uuid + '/unpublish'),
  createSection: (uuid, data) => $nuxt.$axios.post($nuxt.$api.host + '/form/' + uuid + '/section', data),
  updateSection: (form_uuid, section_uuid, data) =>
    $nuxt.$axios.put($nuxt.$api.host + '/form/' + form_uuid + '/section/' + section_uuid, data),
  deleteSection: (form_uuid, section_uuid) =>
    $nuxt.$axios.delete($nuxt.$api.host + '/form/' + form_uuid + '/section/' + section_uuid),
  createQuestion: (form_uuid, data) => $nuxt.$axios.post($nuxt.$api.host + '/form/' + form_uuid + '/question', data),
  updateQuestion: (form_uuid, question_uuid, data) =>
    $nuxt.$axios.put($nuxt.$api.host + '/form/' + form_uuid + '/question/' + question_uuid, data),
  deleteQuestion: (form_uuid, question_uuid) =>
    $nuxt.$axios.delete($nuxt.$api.host + '/form/' + form_uuid + '/question/' + question_uuid),
  getReceivedRequests: (account_uuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/submission-request/received/' + account_uuid, data),
  storeSubmissionRequest: (form_uuid, account_uuid, data) =>
    $nuxt.$axios.post($nuxt.$api.host + '/form/' + form_uuid + '/submission-request/' + account_uuid, data),
  updateSubmissionRequest: (form_uuid, submission_uuid, data) =>
    $nuxt.$axios.put($nuxt.$api.host + '/form/' + form_uuid + '/submission-request/' + submission_uuid, data),
  getAllSubmissionsForForm: (form_uuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/form/' + form_uuid + '/submission', data),
  getSubmission: (form_uuid, submission_uuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/form/' + form_uuid + '/submission/' + submission_uuid, data),
  getSubmissionsForAuthor: (form_uuid, author_uuid, data = null) =>
    $nuxt.$axios.get($nuxt.$api.host + '/form/' + form_uuid + '/submission-authored-by/' + author_uuid, data),
  createSubmission: (form_uuid) => $nuxt.$axios.post($nuxt.$api.host + '/form/' + form_uuid + '/submission/'),
  deleteSubmission: (form_uuid, submission_uuid) =>
    $nuxt.$axios.delete($nuxt.$api.host + '/form/' + form_uuid + '/submission/' + submission_uuid),
  getSingleSubmission: (form_uuid, submission_uuid) =>
    $nuxt.$axios.get($nuxt.$api.host + '/form/' + form_uuid + '/submission/' + submission_uuid),
  submitAnswers: (form_uuid, submission_uuid, data) =>
    $nuxt.$axios.post($nuxt.$api.host + '/form/' + form_uuid + '/submission/' + submission_uuid + '/answer', data),
  changeFromStatusToSubmitted: (form_uuid, submission_uuid, data) =>
    $nuxt.$axios.post($nuxt.$api.host + '/form/' + form_uuid + '/submission/' + submission_uuid + '/submit', data),

  getAll: async (params = null) => await paginator.grabAllPages($nuxt.$api.host + '/form', {}, params),
};
