export default {
  namespaced: true,

  state: () => ({
    noteContents: [],
    caseView: 'list',
    taskView: 'kanban',
    folderView: 'folder',
    accountFilters: {},
    accountFilterOptions: {},
    reportFilters: {},
    reportFilterOptions: {},
    reportTemplateFilters: {},
    reportTemplateFilterOptions: {},
    caseFilters: {},
    caseFilterOptions: {},
    taskFilters: {},
    taskFilterOptions: {},
    planFilters: {},
    planFilterOptions: {},
    expectationFilters: {},
    expectationFilterOptions: {},
    providerStatementFilters: {},
    providerStatementFilterOptions: {},
    statementTransactionFilters: {},
    statementTransactionFilterOptions: {},
    bankStatementFilters: {},
    bankStatementFilterOptions: {},
    bankTransactionFilters: {},
    bankTransactionFilterOptions: {},
    automationFilters: {},
    automationFilterOptions: {},
    automationBlueprintFilters: {},
    automationBlueprintFilterOptions: {},
    conversationFilters: {},
    conversationFilterOptions: {},
    documentFilters: {},
    documentFilterOptions: {},
    documentTemplateFilters: {},
    documentTemplateFilterOptions: {},
    importFilters: {},
    importFilterOptions: {},
    signatureTransactionFilters: {},
    signatureTransactionFilterOptions: {},
    formBuilderFilters: {},
    formBuilderFilterOptions: {},
    notificationPreference: true,
    menuOpen: false,
  }),

  mutations: {
    SET_NOTE_CONTENTS(state, note) {
      if (note.contents !== '') {
        if (state.noteContents.length > 0) {
          state.noteContents.forEach((item) => {
            if (item.notable_uuid === note.notable_uuid) {
              let index = state.noteContents.findIndex((f) => f.notable_uuid === note.notable_uuid);
              state.noteContents[index].contents = note.contents;
              state.noteContents[index].notable_type = note.notable_type;
              state.noteContents[index].notable_type = note.notable_type;
              state.noteContents[index].type = note.type;
            } else {
              state.noteContents.push(note);
            }
          });
        } else {
          state.noteContents.push(note);
        }
      }
    },
    CLEAR_NOTE_CONTENTS(state, notable_uuid) {
      state.noteContents.forEach((item) => {
        if (item.notable_uuid === notable_uuid) {
          let index = state.noteContents.findIndex((item) => item.notable_uuid === notable_uuid);
          state.noteContents.splice(index, 1);
        }
      });
    },
    SET_CASE_VIEW(state, view) {
      state.caseView = view;
    },
    SET_TASK_VIEW(state, view) {
      state.taskView = view;
    },
    SET_FOLDER_VIEW(state, view) {
      state.folderView = view;
    },
    SET_ACCOUNT_FILTERS(state, filters) {
      state.accountFilters = filters.params;
      state.accountFilterOptions = filters.savedFilterOptions;
    },
    SET_REPORT_FILTERS(state, filters) {
      state.reportFilters = filters.params;
      state.reportFilterOptions = filters.savedFilterOptions;
    },
    SET_REPORT_TEMPLATE_FILTERS(state, filters) {
      state.reportTemplateFilters = filters.params;
      state.reportTemplateFilterOptions = filters.savedFilterOptions;
    },
    SET_CASE_FILTERS(state, filters) {
      state.caseFilters = filters.params;
      state.caseFilterOptions = filters.savedFilterOptions;
    },
    SET_TASK_FILTERS(state, filters) {
      state.taskFilters = filters.params;
      state.taskFilterOptions = filters.savedFilterOptions;
    },
    SET_PLAN_FILTERS(state, filters) {
      state.planFilters = filters.params;
      state.planFilterOptions = filters.savedFilterOptions;
    },
    SET_SCHEME_MEMBER_FILTERS(state, filters) {
      state.schemeMemberFilters = filters.params;
      state.schemeMemberFilterOptions = filters.savedFilterOptions;
    },
    SET_STAFF_MEMBER_FILTERS(state, filters) {
      state.staffMemberFilters = filters.params;
      state.staffMemberFilterOptions = filters.savedFilterOptions;
    },
    SET_EXPECTATION_FILTERS(state, filters) {
      state.expectationFilters = filters.params;
      state.expectationFilterOptions = filters.savedFilterOptions;
    },
    SET_PROVIDER_STATEMENT_FILTERS(state, filters) {
      state.providerStatementFilters = filters.params;
      state.providerStatementFilterOptions = filters.savedFilterOptions;
    },
    SET_STATEMENT_TRANSACTION_FILTERS(state, filters) {
      state.statementTransactionFilters = filters.params;
      state.statementTransactionFilterOptions = filters.savedFilterOptions;
    },
    SET_BANK_STATEMENT_FILTERS(state, filters) {
      state.bankStatementFilters = filters.params;
      state.bankStatementFilterOptions = filters.savedFilterOptions;
    },
    SET_BANK_TRANSACTION_FILTERS(state, filters) {
      state.bankTransactionFilters = filters.params;
      state.bankTransactionFilterOptions = filters.savedFilterOptions;
    },
    SET_AUTOMATION_FILTERS(state, filters) {
      state.automationFilters = filters.params;
      state.automationFilterOptions = filters.savedFilterOptions;
    },
    SET_AUTOMATION_BLUEPRINT_FILTERS(state, filters) {
      state.automationBlueprintFilters = filters.params;
      state.automationBlueprintFilterOptions = filters.savedFilterOptions;
    },
    SET_CONVERSATION_FILTERS(state, filters) {
      state.conversationFilters = filters.params;
      state.conversationFilterOptions = filters.savedFilterOptions;
    },
    SET_DOCUMENT_FILTERS(state, filters) {
      state.documentFilters = filters.params;
      state.documentFilterOptions = filters.savedFilterOptions;
    },
    SET_DOCUMENT_TEMPLATE_FILTERS(state, filters) {
      state.documentTemplateFilters = filters.params;
      state.documentTemplateFilterOptions = filters.savedFilterOptions;
    },
    SET_IMPORT_FILTERS(state, filters) {
      state.importFilters = filters.params;
      state.importFilterOptions = filters.savedFilterOptions;
    },
    SET_SIGNATURE_TRANSACTION_FILTERS(state, filters) {
      state.signatureTransactionFilters = filters.params;
      state.signatureTransactionFilterOptions = filters.savedFilterOptions;
    },
    SET_FORM_BUILDER_FILTERS(state, filters) {
      state.formBuilderFilters = filters.params;
      state.formBuilderFilterOptions = filters.savedFilterOptions;
    },
    SET_NOTIFICATION_PREFERENCE(state, filters) {
      state.notificationPreference = filters;
    },
    SET_MENU_OPEN(state, isOpen) {
      state.menuOpen = isOpen;
    },
  },

  actions: {
    setNoteContents({ commit }, note) {
      commit('SET_NOTE_CONTENTS', note);
    },
    clearNoteContents({ commit }, notable_uuid) {
      commit('CLEAR_NOTE_CONTENTS', notable_uuid);
    },
    setCaseView({ commit }, view) {
      commit('SET_CASE_VIEW', view);
    },
    setTaskView({ commit }, view) {
      commit('SET_TASK_VIEW', view);
    },
    setFolderView({ commit }, view) {
      commit('SET_FOLDER_VIEW', view);
    },
    setAccountFilters({ commit }, filters) {
      commit('SET_ACCOUNT_FILTERS', filters);
    },
    setReportFilters({ commit }, filters) {
      commit('SET_REPORT_FILTERS', filters);
    },
    setReportTemplateFilters({ commit }, filters) {
      commit('SET_REPORT_TEMPLATE_FILTERS', filters);
    },
    setCaseFilters({ commit }, filters) {
      commit('SET_CASE_FILTERS', filters);
    },
    setTaskFilters({ commit }, filters) {
      commit('SET_TASK_FILTERS', filters);
    },
    setPlanFilters({ commit }, filters) {
      commit('SET_PLAN_FILTERS', filters);
    },
    setSchemeMemberFilters({ commit }, filters) {
      commit('SET_SCHEME_MEMBER_FILTERS', filters);
    },
    setStaffMemberFilters({ commit }, filters) {
      commit('SET_STAFF_MEMBER_FILTERS', filters);
    },
    setExpectationFilters({ commit }, filters) {
      commit('SET_EXPECTATION_FILTERS', filters);
    },
    setProviderStatementFilters({ commit }, filters) {
      commit('SET_PROVIDER_STATEMENT_FILTERS', filters);
    },
    setStatementTransactionFilters({ commit }, filters) {
      commit('SET_STATEMENT_TRANSACTION_FILTERS', filters);
    },
    setBankStatementFilters({ commit }, filters) {
      commit('SET_BANK_STATEMENT_FILTERS', filters);
    },
    setBankTransactionFilters({ commit }, filters) {
      commit('SET_BANK_TRANSACTION_FILTERS', filters);
    },
    setAutomationFilters({ commit }, filters) {
      commit('SET_AUTOMATION_FILTERS', filters);
    },
    setAutomationBlueprintFilters({ commit }, filters) {
      commit('SET_AUTOMATION_BLUEPRINT_FILTERS', filters);
    },
    setConversationFilters({ commit }, filters) {
      commit('SET_CONVERSATION_FILTERS', filters);
    },
    setDocumentFilters({ commit }, filters) {
      commit('SET_DOCUMENT_FILTERS', filters);
    },
    setDocumentTemplateFilters({ commit }, filters) {
      commit('SET_DOCUMENT_TEMPLATE_FILTERS', filters);
    },
    setImportFilters({ commit }, filters) {
      commit('SET_IMPORT_FILTERS', filters);
    },
    setSignatureTransactionFilters({ commit }, filters) {
      commit('SET_SIGNATURE_TRANSACTION_FILTERS', filters);
    },
    setFormBuilderFilters({ commit }, filters) {
      commit('SET_FORM_BUILDER_FILTERS', filters);
    },
    setNotificationPreference({ commit }, filters) {
      commit('SET_NOTIFICATION_PREFERENCE', filters);
    },
    setMenuOpen({ commit }, isOpen) {
      commit('SET_MENU_OPEN', isOpen);
    },
  },
};
