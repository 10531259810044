
import hasCurrentFirmUuid from '../mixins/hasCurrentFirmUuid';
import casesAPI from '../api/cases';

export default {
  name: 'ClientCasesMultiSelect',

  mixins: [hasCurrentFirmUuid],

  props: {
    client_uuid: {
      type: String | null,
      required: false,
      default: null,
    },
    value: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      caseList: [],
      loading: true,
    };
  },

  created() {
    this.grabCases();
  },

  computed: {
    selectedList: {
      get: function () {
        return this.caseList.find(
          (item) => item.uuid === (this.value === typeof Object ? this.value.uuid : this.value)
        );
      },
      set: function (newVal) {
        if (newVal) {
          this.$emit('input', newVal.uuid);
        } else {
          this.$emit('input', null);
        }
      },
    },
  },

  methods: {
    grabCases() {
      if (this.client_uuid == null) {
        casesAPI
          .getCases()
          .then((rsp) => {
            this.caseList = rsp.data.data;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$nuxt.$emit('notification', err.response.data.message, 'warning', true);
          });
      } else {
        casesAPI
          .getAllClientCases(this.client_uuid)
          .then((rsp) => {
            this.caseList = rsp;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$nuxt.$emit('notification', err.response.data.message, 'warning', true);
          });
      }
    },
  },

  watch: {
    client_uuid(new_val) {
      this.loading = true;
      this.grabCases();
    },
  },
};
